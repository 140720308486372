import request from 'utils/request';
const api = {
  DATA_FILTER_ADD: '/api/admin/mc/upload/task/add',
  DATA_FILTER_SEARCH: '/api/admin/mc/search/task/add',
  DATA_UPLOAD_PAGE: '/api/admin/mc/upload/result/page',
  DATA_UPLOAD_DETAILS: '/api/admin/mc/upload/result/get',
  DATA_UPLOAD_UPDATE: '/api/admin/mc/upload/result/update',
  DATA_UPLOAD_DEL: '/api/admin/mc/upload/result/delete',
  DATA_SEARCH_PAGE: '/api/admin/mc/search/page',
  DATA_SEARCH_DEL: '/api/admin/mc/search/delete',
  DATA_SEARCH_VIEW: '/api/admin/mc/search/view',
  DATA_RESULT_LIST: '/api/admin/mc/upload/result/list',
  DATA_UPLOAD_NAME_UPDATE: '/api/admin/mc/upload/result/name/update',
  DATA_DEFAULT_UPLOAD_LIST: '/api/admin/holo/upload/default/getDefaultUploadByCountries',
};

// 数据过滤上传
export function handleDataFilterAdd(data) {
  return request({
    url: api.DATA_FILTER_ADD,
    method: 'post',
    data,
  });
}

// 数据过滤查询
export function handleDataFilterSearch(data) {
  return request({
    url: api.DATA_FILTER_SEARCH,
    method: 'post',
    data,
  });
}

// 上传记录 分页
export function fetchDataUploadPage(query) {
  return request({
    url: api.DATA_UPLOAD_PAGE,
    method: 'get',
    params: query,
  });
}

// 上传记录 详情
export function fetchDataUploadDetails(query) {
  return request({
    url: api.DATA_UPLOAD_DETAILS,
    method: 'get',
    params: query,
  });
}

// 上传记录 更新状态
export function handleDataUploadStatus(data) {
  return request({
    url: api.DATA_UPLOAD_UPDATE,
    method: 'post',
    data,
  });
}
// 上传记录 删除
export function fetchDataUploadDel(query) {
  return request({
    url: api.DATA_UPLOAD_DEL,
    method: 'get',
    params: query,
  });
}

// 查询记录 分页
export function fetchDataSearchPage(query) {
  return request({
    url: api.DATA_SEARCH_PAGE,
    method: 'get',
    params: query,
  });
}

// 查询记录 删除
export function fetchDataSearchDel(query) {
  return request({
    url: api.DATA_SEARCH_DEL,
    method: 'get',
    params: query,
  });
}

// 查询记录 查看数据
export function fetchDataSearchView(query) {
  return request({
    url: api.DATA_SEARCH_VIEW,
    method: 'get',
    params: query,
  });
}

// 查询数据包记录
export function fetchDataResultList(query) {
  return request({
    url: api.DATA_RESULT_LIST,
    method: 'get',
    params: query,
  });
}

export function updateDeviceNameAsyncInfo(query) {
  return request({
    url: api.DATA_UPLOAD_NAME_UPDATE,
    method: 'get',
    params: query,
  });
}

// 查询默认数据包记录
export function fetchDefaultDataResultList(query) {
  return request({
    url: api.DATA_DEFAULT_UPLOAD_LIST,
    method: 'get',
    params: query,
  });
}
